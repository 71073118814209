<template>
<div class="kline">

    <!-- <p>{{ state.Code }}</p> -->
    <h5 v-if="state.Code != undefined && state.Code != null && state.Code.length > 0 ">你选择了代码搜索{{ state.Code }}</h5>
    <h5 v-if="state.BeginDate !=undefined && state.BeginDate !=null && state.BeginDate !=''"> 你选择了开始时间{{ state.BeginDate }}</h5>
    <h5 v-if="state.AdjustDate != undefined && state.AdjustDate != null && state.AdjustDate!='' ">你选择了校准时间{{ state.AdjustDate }}</h5>
    <h5 v-if="state.DemoDate != undefined && state.DemoDate != null && state.DemoDate!='' ">你选择了推演时间{{ state.DemoDate }}</h5>
    <h5 v-if="state.ModelsValue != undefined && state.ModelsValue != null && state.ModelsValue!='' ">你选择了模型{{ state.ModelsValue }}</h5>
    <h5 v-if="state.SettingsValue != undefined && state.SettingsValue != null && state.SettingsValue.length > 0 ">你选择了设定{{ state.SettingsValue }}</h5>

    <div>
        <kline/>
    </div>

    <div>
        <kline2/>
    </div>

</div>
</template>

<script>
import Kline from '../components/Kline.vue'
import Kline2 from '../components/Kline2.vue'
import store from "../store"

export default{
    
    name:'SimModel',
    components: { Kline,Kline2 },
    data(){
        return{
            state:store.state
        }
    }
    
}

</script>

