<template>
<div class="container" > 
  <!-- <div class="container" style="background-color: rgb(45, 133, 188);text-align: center;">  -->
    <div >
        <div class="block">
            <span class="demonstration">代码搜索</span>
            <el-input v-model="Code" placeholder="请输入搜索内容" ></el-input>
        </div>
    </div>
    
    <div >
        <div class="block">
            <span class="demonstration">开始时间</span>
            <el-date-picker  v-model="BeginDate" type="date" placeholder="选择日期" style="width: 100%;" > </el-date-picker>
        </div>
    </div>

    <div >
        <div class="block">
            <span class="demonstration">校准时间</span>
            <el-date-picker  v-model="AdjustDate" type="date" placeholder="选择日期" style="width: 100%;" > </el-date-picker>
        </div>
    </div>

    <div >
        <div class="block">
            <span class="demonstration">推演时间</span>
            <el-date-picker  v-model="DemoDate" type="date" placeholder="选择日期" style="width: 100%;" >   </el-date-picker>
        </div>
    </div>


    <div >
        <div class="block">
            <div><span class="demonstration">添加模型</span></div>
            
            
            <el-select v-model="ModelsValue" multiple placeholder="请选择" >
                <el-option
                v-for="item in Models"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div>
    </div>

    <div >

    <div class="block">
            <div><span class="demonstration">演示设定</span></div>
            
            <el-select v-model="SettingsValue" placeholder="请选择" >
                <el-option
                v-for="item in Settings"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div>
    </div>

    <div >
        <div class="block">
          <p></p>
          <button type="button" class="btn btn-primary btn-lg btn-block" style="width: 100%;" @click="sendcode">开始仿真</button>
            <!-- <el-input v-model="input" placeholder="请输入搜索内容"></el-input> -->
            <div>
              <img alt="Vue logo" src="../assets/sustech.png" style="width: 100%;">
            </div>
            <div>
              <p>2023/9/18 </p>
              <p></p>
            </div>
           
        </div>
    </div>


</div>
</template>

<script>

import store from "../store"

export default{
  name: 'SimSetting',
  data(){
    return {
        Code:"",
        BeginDate:"",
        AdjustDate:"",
        DemoDate:"",
        Models: [{
          value: '选项1',
          label: '模型1'
        }, {
          value: '选项2',
          label: '模型2'
        }, {
          value: '选项3',
          label: '模型3'
        }, {
          value: '选项4',
          label: '模型4'
        }, {
          value: '选项5',
          label: '模型5'
        }],
        ModelsValue: [],

        Settings: [{
          value: '选项1',
          label: '设定1'
        }, {
          value: '选项2',
          label: '设定2'
        }, {
          value: '选项3',
          label: '设定3'
        }, {
          value: '选项4',
          label: '设定4'
        }, {
          value: '选项5',
          label: '设定5'
        }],
        SettingsValue: ''
      
    }

    },
    methods:{
      sendcode(){
        store.state.Code=this.Code
        store.state.BeginDate=this.BeginDate
        store.state.AdjustDate=this.AdjustDate
        store.state.DemoDate=this.DemoDate
        store.state.ModelsValue=this.ModelsValue
        store.state.SettingsValue=this.SettingsValue
  

        console.log("传值成功",this.ModelsValue,this.SettingsValue)

        
      }
  }
}
</script>