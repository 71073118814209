<template>
    <div>
        <div>模型指标对比图</div>
        <!-- <img alt="Vue logo" src="../assets/graph.jpg" style="width: 100%;"> -->
        
        <div id="myChart-line" style="height: 200%;"></div>
        <!-- <p>-------------------------------------</p> -->

    </div>
</template>

<script>
    import * as echarts from 'echarts' //echarts5.0的引入方式和4.0有所不同


export default {
    mounted(){
    const myChart = echarts.init(document.getElementById('myChart-line'));
    const options ={
        title: {
            text: ''
        },
        xAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'line'
        }]
    };
    myChart.setOption(options);
}

}

</script>