<template>
    <div>
      
        <div id="container" style="height: 300px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" _echarts_instance_="ec_1695781606466">
            <!-- <div style="position: relative; width: 310px; height: 811px; padding: 0px; margin: 0px; border-width: 0px; cursor: default;"></div> -->
        </div>
    
    </div>
    </template>
    
    <script>
    

    import * as echarts from 'echarts' //echarts5.0的引入方式和4.0有所不同
    
    export default{
        name:'KLine',
        mounted(){
            var dom = document.getElementById('container');
            var myChart = echarts.init(dom, null, {
            renderer: 'canvas',
            useDirtyRect: false
            });
            // var app = {};
            // var ROOT_PATH = 'https://echarts.apache.org/examples';
            // var option;
    
            const upColor = '#00da3c';
            const downColor = '#ec0000';
    
            function splitData(rawData) {
                let categoryData = [];
                let values = [];
                let volumes = [];
                for (let i = 0; i < rawData.length; i++) {
                    categoryData.push(rawData[i].splice(0, 1)[0]);
                    values.push(rawData[i]);
                    volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
                }
                return {
                    categoryData: categoryData,
                    values: values,
                    volumes: volumes
                };
            }
    
            function calculateMA(dayCount, data) {
                var result = [];
                for (var i = 0, len = data.values.length; i < len; i++) {
                    if (i < dayCount) {
                    result.push('-');
                    continue;
                    }
                    var sum = 0;
                    for (var j = 0; j < dayCount; j++) {
                    sum += data.values[i - j][1];
                    }
                    result.push(+(sum / dayCount).toFixed(3));
                }
                return result;
            }
    
    // 注意，axios访问数据时，根目录在public上，而不是跟这个vue文件有关。
            this.$axios.get(process.env.BASE_URL + 'stock.json').then(response => {
            var data = splitData(response.data);
    //---------setoption
            console.log("stock",data)
            myChart.setOption(
                (  {
                animation: false,
                legend: {
                    bottom: 10,
                    left: 'center',
                    data: ['Dow-Jones index', 'MA5', 'MA10', 'MA20', 'MA30']
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'cross'
                    },
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                    color: '#000'
                    },
                    position: function (pos, params, el, elRect, size) {
                    const obj = {
                        top: 10
                    };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                    }
                    // extraCssText: 'width: 170px'
                },
                axisPointer: {
                    link: [
                    {
                        xAxisIndex: 'all'
                    }
                    ],
                    label: {
                    backgroundColor: '#777'
                    }
                },
                toolbox: {
                    feature: {
                    dataZoom: {
                        yAxisIndex: false
                    },
                    brush: {
                        type: ['lineX', 'clear']
                    }
                    }
                },
                brush: {
                    xAxisIndex: 'all',
                    brushLink: 'all',
                    outOfBrush: {
                    colorAlpha: 0.1
                    }
                },
                visualMap: {
                    show: false,
                    seriesIndex: 5,
                    dimension: 2,
                    pieces: [
                    {
                        value: 1,
                        color: downColor
                    },
                    {
                        value: -1,
                        color: upColor
                    }
                    ]
                },
                grid: [
                    {
                    left: '10%',
                    right: '8%',
                    height: '50%'
                    },
                    {
                    left: '10%',
                    right: '8%',
                    top: '63%',
                    height: '16%'
                    }
                ],
                xAxis: [
                    {
                    type: 'category',
                    data: data.categoryData,
                    boundaryGap: false,
                    axisLine: { onZero: false },
                    splitLine: { show: false },
                    min: 'dataMin',
                    max: 'dataMax',
                    axisPointer: {
                        z: 100
                    }
                    },
                    {
                    type: 'category',
                    gridIndex: 1,
                    data: data.categoryData,
                    boundaryGap: false,
                    axisLine: { onZero: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLabel: { show: false },
                    min: 'dataMin',
                    max: 'dataMax'
                    }
                ],
                yAxis: [
                    {
                    scale: true,
                    splitArea: {
                        show: true
                    }
                    },
                    {
                    scale: true,
                    gridIndex: 1,
                    splitNumber: 2,
                    axisLabel: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false }
                    }
                ],
                dataZoom: [
                    {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 98,
                    end: 100
                    },
                    {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '85%',
                    start: 98,
                    end: 100
                    }
                ],
                series: [
                    {
                    name: 'Dow-Jones index',
                    type: 'candlestick',
                    data: data.values,
                    itemStyle: {
                        color: upColor,
                        color0: downColor,
                        borderColor: undefined,
                        borderColor0: undefined
                    }
                    },
                    {
                    name: 'MA5',
                    type: 'line',
                    data: calculateMA(5, data),
                    smooth: true,
                    lineStyle: {
                        opacity: 0.5
                    }
                    },
                    {
                    name: 'MA10',
                    type: 'line',
                    data: calculateMA(10, data),
                    smooth: true,
                    lineStyle: {
                        opacity: 0.5
                    }
                    },
                    {
                    name: 'MA20',
                    type: 'line',
                    data: calculateMA(20, data),
                    smooth: true,
                    lineStyle: {
                        opacity: 0.5
                    }
                    },
                    {
                    name: 'MA30',
                    type: 'line',
                    data: calculateMA(30, data),
                    smooth: true,
                    lineStyle: {
                        opacity: 0.5
                    }
                    },
                    {
                    name: 'Volume',
                    type: 'bar',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    data: data.volumes
                    }
                ]
                }),
                true
            );
    //---------endofsetoption
    // -----------------------
            myChart.dispatchAction({
                type: 'brush',
                areas: [
                {
                    brushType: 'lineX',
                    coordRange: ['2004-06-02', '2016-06-20'],
                    xAxisIndex: 0
                }
                ]
            });
    // -----------------------
    
            }).catch(err=>{
            console.log("本地stock.json数据未取到",err)
        });
        },
    }
    
    </script>
    
    