<template>
  <div id="app">
    <div style="background-color: rgb(45, 133, 188);text-align: center;"><H1>证券市场交易仿真系统</h1></div>
 
    <div class="container-fluid" >

      <div class="row" style="height: 600px;">
        <div class="col-2" style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">
          <SimSetting/>
          <!-- <button type="button" class="btn btn-primary btn-lg btn-block">Block level button</button> -->
        </div>

        <div class="col-7" >
            <div class="rounded-1 border" style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);">
                  <SimModel/>
            </div>

        </div>

        <div class="col-3"  style="height: 600px;">

          <div style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);height: 200px;">
            <SimMetricsGraph/>
          </div>
          
          <div style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);height: 200px;">
            <SimMetricsChart/>
          </div>
          
          <div style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);height: 200px;">
            <OutsideOrder/>

          </div>

        </div>

      </div>
    </div>
    
  </div>
</template>

<script>


import SimSetting from './views/SimSetting.vue'
import SimModel from './views/SimModel.vue'
import SimMetricsGraph from './views/SimMetricsGraph.vue'
import SimMetricsChart from './views/SimMetricsChart.vue'
import OutsideOrder from './views/OutsideOrder.vue'

export default {
  name: 'App',
  components: {
    SimSetting,SimModel,SimMetricsGraph,SimMetricsChart,OutsideOrder
  }
}
</script>
