<template>
    
    <div>
        
        <div>聚合指标对比图</div>
        <!-- <img alt="Vue logo" src="../assets/chart.jpg" style="width: 100%;"> -->
        
        <div id="myChart-bar" style="height: 200%;"></div>
        <!-- <div id="myChart-histogram" style="width: 400px; height: 400px;"></div> -->
        <!-- <div id="myChart-pie" style="width: 400px;height:400px;"></div> -->
      
        <!-- <p>-------------------------------------</p> -->
    </div>
</template>


<script>

    import * as echarts from 'echarts' //echarts5.0的引入方式和4.0有所不同

    export default {
        mounted(){
        const myChart = echarts.init(document.getElementById('myChart-bar'));
        const options ={
            title: {
                text: ''
            },
            xAxis: {
                type: 'category',
                data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'bar'
            }]
        };
        myChart.setOption(options);
    }

    }


</script>