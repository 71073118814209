import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'


Vue.config.productionTip = false;
Vue.use(ElementUI,{ size: 'small'});

// import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css'

import axios from 'axios'
Vue.prototype.$axios = axios

new Vue({
  render: h => h(App),
}).$mount('#app')

