export default{
    state:{
        // message:"hello vue",
        Code:"",
        BeginDate:"",
        AdjustDate:"",
        DemoDate:"",
        ModelsValue:[],
        SettingsValue:""
    },
    // setStateMessage(str){
    //     this.state.message=str;
    // }
}